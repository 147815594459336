const circleBranch = process.env.CIRCLE_BRANCH

const envObj = {
  API_URL: process.env.API_URL || '',
  APP_URL: process.env.APP_URL || 'http://localhost:8081',

  MAIL_TO: process.env.MAIL_TO || 'sales@datawow.io,support@datawoww.io',
  EMAIL_API_URL: process.env.EMAIL_API_URL,
  SLACK_WEBHOOK_URL: process.env.SLACK_WEBHOOK_URL,
  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL,
  CONSENT_WOW_API_KEY: process.env.CONSENT_WOW_API_KEY,
  CONSENT_WOW_OBJECTIVE_TOKEN: process.env.CONSENT_WOW_OBJECTIVE_TOKEN,

  PDPA_BLOG_API_KEY: process.env.PDPA_BLOG_API_KEY,
  PDPA_BLOG_API_URL: process.env.PDPA_BLOG_API_URL,
  PDPA_BLOG_URL: process.env.PDPA_BLOG_URL,

  GTM_CONTAINER_ID: '',

  IS_DEV: true,
}

if (circleBranch === 'production') {
  envObj.IS_DEV = false
  envObj.GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID || ''
  envObj.APP_URL = process.env.PRODUCTION_APP_URL || ''
  envObj.API_URL = process.env.PRODUCTION_API_URL || ''
}

if (circleBranch === 'main') {
  envObj.APP_URL = process.env.STAGING_APP_URL || ''
  envObj.API_URL = process.env.STAGING_API_URL || ''
}

export default { ...envObj }
